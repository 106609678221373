import http from "../http-common";

class ClassProjectDataService {
  getAll(active) {
    return http.get(`/projects?active=${active}`);
  }

  getActiveWithComponents() {
    return http.get(`/projects?components=true`);
  }


  getOverview(key) {
    return http.get(`/projects/${key}/overview`);
  }

  getSingle(id) {
    return http.get(`projects/${id}`);
  }

  createSingle(data) {
    return http.post(`/projects/`, data)
  }

  updateSingle(id, data) {
    return http.put(`/projects/${id}`, data)
  }

  deleteSingle(id) {
    return http.delete(`/projects/${id}`);
  }

}

const ProjectDataService = new ClassProjectDataService();
export default ProjectDataService;
