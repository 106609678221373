import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import JiraDataService from "services/jira.service";
import TaskDataService from "services/task.service";
import { useAlertMessageStore, useLoadingStore } from "store/store";

import Card from "@mui/material/Card";
import { JiraLayout } from "components/layouts/jira";
import ImportTaskForm from "./import-form";

export default function ImportTask() {
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);

  const INITIAL_VALUES = {
    title: "",
    assignee: {},
    components: {},
  };
  const navigate = useNavigate();

  const handleFormSubmit = (data) => {
    useLoadingStore.setState({ isLoading: true });
    JiraDataService.importMultiple(data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Task wurde erfolgreich importiert",
          },
        });
        navigate("/jira/tasks");
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Importieren des Tasks",
          },
        });
      });
  };

  useEffect(() => {
    function getTasks() {
      TaskDataService.getAll()
        .then((response) => {
          setTasks(response.data.tasks);
          useLoadingStore.setState({ isLoading: false });
        })
        .catch((error) => {
          useLoadingStore.setState({ isLoading: false });
          console.dir(error);
        });
    }

    function getComponents() {
      JiraDataService.getComponents("all")
        .then((response) => {
          setProjects(response.data);
          useLoadingStore.setState({ isLoading: false });
        })
        .catch((error) => {
          useLoadingStore.setState({ isLoading: false });
          console.dir(error);
        });
    }

    useLoadingStore.setState({ isLoading: true });
    getTasks();
    getComponents();
  }, []);

  return (
    <JiraLayout title="Task importieren">
      <Card
        sx={{
          p: 0,
          mb: "30px",
        }}
      >
        <ImportTaskForm
          initialValues={INITIAL_VALUES}
          handleFormSubmit={handleFormSubmit}
          taskList={tasks}
          projects={projects}
        />
      </Card>
    </JiraLayout>
  );
}
