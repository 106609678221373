import { authenticationService } from '../auth/authentication.service';
import { useUserStore } from 'store/store';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = useUserStore.getState().currentUser;

    if (currentUser && currentUser.token) {
        return `Bearer ${currentUser.token}`;
    } else {
        return {};
    }
}