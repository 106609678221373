import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import JiraDataService from "services/jira.service";
import { useAlertMessageStore, useLoadingStore } from "store/store";
import TaskDataService from "services/task.service";

import Box from "@mui/material/Box";

import { JiraLayout } from "components/layouts/jira";
import TaskForm from "./task-form";

export default function CreateTask() {
  const [projects, setProjects] = useState([]);

  const INITIAL_VALUES = {
    description: "",
    title: "",
    components: {},
  };
  const navigate = useNavigate();

  const handleFormSubmit = (data) => {
    useLoadingStore.setState({ isLoading: true });
    TaskDataService.createSingle(data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Task wurde erfolgreich erstellt",
          },
        });
        navigate("/jira/tasks");
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Erstellen des Tasks",
          },
        });
      });
  };

  function getComponents() {
    JiraDataService.getComponents("all")
      .then((response) => {
        setProjects(response.data);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    getComponents();
  }, []);

  return (
    <JiraLayout title="Task erstellen">
      <Box>
        <TaskForm
          handleFormSubmit={handleFormSubmit}
          task={INITIAL_VALUES}
          projects={projects}
          buttonText="Task erstellen"
        />
      </Box>
    </JiraLayout>
  );
}
