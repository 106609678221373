import http from "../http-common";
import { handleResponse } from "helpers/handle-response";

class ClassSevDeskDataService {
  getAll(key) {
    return http.get(`/sevdesk/invoice?key=${key}`);
  }

  get(id) {
    return http.get(`/sevdesk/invoice/${id}`);
  }

  delete(id) {
    return http.delete(`/projects/${id}`);
  }
  
  getPDF(id){ 
    return http.get(`/sevdesk/invoice/${id}/getPDF`, {responseType: 'blob' });
  }

}

const SevDeskDataService = new ClassSevDeskDataService();
export default SevDeskDataService;
