import http from "../http-common";
import { handleResponse } from "helpers/handle-response";

class ClassJiraDataService {
  getAll(key) {
    return http.get(`/jira/task?key=${key}`);
  }

  getTimesheet(type, id, startDate, endDate) {
    return http.get(`/jira/timesheet?type=${type}&id=${id}&startDate=${startDate}&endDate=${endDate}`);
  }

  getTimesheetPDF(type, id, startDate, endDate, invoice) {
    return http.get(`/jira/timesheet/getPDF?type=${type}&id=${id}&startDate=${startDate}&endDate=${endDate}&invoice=${invoice}`,  {responseType: 'blob' });
  }

  getComponents(key) {
    return http.get(`/jira/component?key=${key}`);
  }

  importMultiple(data) {
    return http.post(`/jira/task/import`, data);
  }

}

const JiraDataService = new ClassJiraDataService();
export default JiraDataService;
