import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx"; // LOCAL CUSTOM HOOKS

import MobileHeader from "./mobile-header";

import { HeaderWrapper, StyledContainer } from "./styles"; // ==============================================================

// ==============================================================
const Header = ({ className, searchInput }) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down(1150));

  return (
    <HeaderWrapper className={clsx(className)}>
      <StyledContainer>
        {downMd && <MobileHeader searchInput={searchInput} />}
      </StyledContainer>
    </HeaderWrapper>
  );
};

export default Header;
