import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAlertMessageStore, useLoadingStore } from "store/store";
import ProjectDataService from "services/project.service";

import Box from "@mui/material/Box";

import { AdministrationLayout } from "components/layouts/administration";
import ProjectForm from "./project-form";

export default function CreateProject() {
  const INITIAL_VALUES = {
    id: "",
    displayName: "",
    jiraKey: "",
    sevdeskID: "",
    active: false,
  };
  const navigate = useNavigate();

  const handleFormSubmit = (data) => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.createSingle(data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Das Projekt wurde erfolgreich erstellt",
          },
        });
        navigate("/administration/projects");
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Erstellen des Projekts",
          },
        });
      });
  };

  useEffect(() => {
    useLoadingStore.setState({ isLoading: false });
  }, []);

  return (
    <AdministrationLayout title="Projekt erstellen">
      <Box>
        <ProjectForm handleFormSubmit={handleFormSubmit} project={INITIAL_VALUES} />
      </Box>
    </AdministrationLayout>
  );
}
