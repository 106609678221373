import { Link, useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem"; // MUI ICON COMPONENTS

import ArrowRight from "@mui/icons-material/ArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"; // GLOBAL CUSTOM COMPONENTS

import { Span } from "components/Typography";
import { NavLink } from "components/nav-link";
import { FlexBox } from "components/flex-box";

import navigation from "data/navbarNavigation"; // STYLED COMPONENTS
import StyledCard from "components/StyledCard";

import {
  ParentNav,
  ParentNavItem,
  StyledNavLink,
  NavBarWrapper,
  InnerContainer,
  NAV_LINK_STYLES,
  ChildNavListWrapper,
} from "./styles"; // DATA TYPES

// ==========================================================
const Navbar = ({
  border,
  elevation = 2,
  navListOpen = false,
  hideCategories = false,
}) => {
  const pathname = useLocation().pathname;

  const renderNestedNav = (list = [], isRoot = false) => {
    return list.map((nav) => {
      if (isRoot) {
        if (nav.url && !nav.child) {
          return (
            <StyledNavLink to={nav.url} key={nav.title}>
              {nav.title}
            </StyledNavLink>
          )
        }

        if (nav.child) {
          return (
            <FlexBox
              key={nav.title}
              alignItems="center"
              position="relative"
              flexDirection="column"
              sx={{
                "&:hover": {
                  "& > .child-nav-item": {
                    display: "block",
                  },
                },
              }}
            >
              <FlexBox alignItems="flex-end" gap={0.3} sx={NAV_LINK_STYLES}>
                {nav.url ? (
                  <StyledNavLink to={nav.url} key={nav.title}>
                    {nav.title}
                  </StyledNavLink>
                ) : (
                  nav.title
                )}
                <KeyboardArrowDown
                  sx={{
                    color: "grey.500",
                    fontSize: "1.1rem",
                  }}
                />
              </FlexBox>

              <ChildNavListWrapper className="child-nav-item">
                <StyledCard
                  elevation={3}
                  sx={{
                    mt: 2.5,
                    py: 1,
                    minWidth: 200,
                  }}
                >
                  {renderNestedNav(nav.child)}
                </StyledCard>
              </ChildNavListWrapper>
            </FlexBox>
          );
        }
      } else {
        if (nav.url) {
          return (
            <NavLink to={nav.url} key={nav.title}>
              <MenuItem>{nav.title}</MenuItem>
            </NavLink>
          );
        }

        if (nav.child) {
          const isActive = nav.child
            .flat()
            .find((item) => item.url === pathname);
          return (
            <ParentNav key={nav.title} minWidth={230} active={isActive ? 1 : 0}>
              <MenuItem color="grey.700">
                <Span flex="1 1 0">{nav.title}</Span>

                <ArrowRight fontSize="small" />

              </MenuItem>

              <ParentNavItem className="parent-nav-item">
                <StyledCard
                  sx={{
                    py: "0.5rem",
                    minWidth: "230px",
                  }}
                  elevation={3}
                >
                  {renderNestedNav(nav.child)}
                </StyledCard>
              </ParentNavItem>
            </ParentNav>
          );
        }
      }
    });
  };

  const CONTENT = (
    <FlexBox mx={'auto'} gap={4}>{renderNestedNav(navigation, true)}</FlexBox>
  );
  return (
    <NavBarWrapper hoverEffect={false} elevation={elevation} border={border}>
      {hideCategories ? (
        <InnerContainer
          sx={{
            justifyContent: "center",
          }}
        >
          {CONTENT}
        </InnerContainer>
      ) : (
        <InnerContainer>

          {/* HORIZONTAL MENU */}
          {CONTENT}
        </InnerContainer>
      )}
    </NavBarWrapper>
  );
};

export default Navbar;
