import { styled } from "@mui/material/styles";

import { Menu } from "@mui/icons-material"; // GLOBAL CUSTOM COMPONENTS

import { H2 } from "components/Typography";
import { SideNav } from "components/side-nav";
import FlexBox from "components/flex-box/flex-box";
import AdministrationNavigation from "components/layouts/administration/navigation";
import DashboardNavigation  from "components/layouts/dashboard/navigation"; 
import JiraNavigation from "components/layouts/jira/navigation";
import TimesheetNavigation  from "components/layouts/timesheet/navigation";

const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(-2),
  marginBottom: theme.spacing(3),
  "& .headerHold": {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("md")]: {
    "& .sidenav": {
      display: "none",
    },
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
})); // ==============================================================

// ==============================================================
const Header = ({ title, Icon, section, navigationData }) => {
  return (
    <StyledBox>
      <FlexBox mt={2} className="headerHold">
        <FlexBox alignItems="center" gap={1.5}>
          {Icon && <Icon color="primary" />}

          <H2 my={0} lineHeight={1}>
            {title ? title : "Übersicht"}
          </H2>
        </FlexBox>

        <div className="sidenav">
          <SideNav position="left" handle={<Menu fontSize="small" />}>
            { section === "administration" && <AdministrationNavigation />}
            { section === "dashboard" && <DashboardNavigation projects={navigationData} /> }
            { section === "jira" && <JiraNavigation /> }
            { section === "timesheet" && <TimesheetNavigation /> }
          </SideNav>
        </div>
      </FlexBox>
    </StyledBox>
  );
};

export default Header;
