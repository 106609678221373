import http from "../http-common";

class ClassTaskDataService {
  getAll() {
    return http.get(`/tasks`);
  }

  getSingle(id) {
    return http.get(`tasks/${id}`);
  }

  createSingle(data) {
    return http.post(`/tasks/`, data)
  }

  updateSingle(id, data) {
    return http.put(`/tasks/${id}`, data)
  }

  deleteSingle(id) {
    return http.delete(`/tasks/${id}`);
  }

}

const TaskDataService = new ClassTaskDataService();
export default TaskDataService;
