import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAlertMessageStore, useLoadingStore } from "store/store";
import ProjectDataService from "services/project.service";

import Box from "@mui/material/Box";

import { AdministrationLayout } from "components/layouts/administration";
import ProjectForm from "./project-form";

export default function ProjectDetails() {
  const [project, setProject] = useState({
    id: "",
    displayName: "",
    jiraKey: "",
    sevdeskID: "",
    active: false,
  });
  const { key } = useParams();
  const navigate = useNavigate();

  const handleFormSubmit = data => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.updateSingle(key, data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Das Projekt wurde erfolgreich gespeichert",
          },
        });
        navigate("/administration/projects")
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Speichern des Projekts",
          },
        });
      });
  };

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    getProject();
  }, []);

  function getProject() {
    ProjectDataService.getSingle(key)
      .then((response) => {
        setProject(response.data);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }

  return (
    <AdministrationLayout title={`${project.displayName}` || "Projekt verwalten"}>
      <Box>
        <ProjectForm handleFormSubmit={handleFormSubmit} project={project} />
      </Box>
    </AdministrationLayout>
  );
}
