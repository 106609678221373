import { useState } from "react";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Formik, FieldArray } from "formik";
import Grid from "@mui/material/Grid";
import { Masonry } from "@mui/lab";
import TextField from "@mui/material/TextField";

import ComponentBox from "components/tasks/ComponentBox";

const VALIDATION_SCHEMA = yup.object().shape({
  project: yup.object().nullable(true).required("Bitte ein Projekt wählen"),
  startDate: yup.date().required("Bitte ein Startdatum eingeben"),
  endDate: yup.date().required("Bitte ein Enddatum eingeben"),
  invoice: yup.string(),
}); // ================================================================

// ================================================================
const TaskForm = (props) => {
  const { task, handleFormSubmit, projects, buttonText } = props;

  return (
    <Card
      sx={{
        p: { xs: 1, md: 6 },
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={task}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <TextField
                  id="description"
                  label="Beschreibung"
                  name="description"
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="title"
                  label="Jira-Titel"
                  name="title"
                  variant="outlined"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Masonry
                  columns={{ xs: 1, md: 2, xl: 3 }}
                  spacing={2}
                  sx={{ width: "auto" }}
                >
                  {projects.map((project) => {
                    return (
                      <ComponentBox
                        project={project}
                        values={values}
                        setFieldValue={setFieldValue}
                        FieldArray={FieldArray}
                      />
                    );
                  })}
                </Masonry>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  sx={{
                    display: "block",
                    margin: "auto",
                    textTransform: "uppercase",
                  }}
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default TaskForm;
