import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { deDE } from '@mui/material/locale';
import { BrowserRouter, } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { components } from "theme/components";
import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import de from 'date-fns/locale/de';

const theme = createTheme({
  components: {
    ...components,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560,
    }
  },
  palette: {
    primary: {
      main: '#547890',
      white: '#ffffff',
    },
    gainsboro: {
      main: '#e0e0e0',
    }
  },
}, deDE);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();


