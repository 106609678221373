import { Add } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { FlexBox } from "components/flex-box";
//<SearchInput placeholder={searchPlaceholder} onChange={onChange} />import SearchInput from "components/SearchInput";
import { Link } from "react-router-dom";

// ===============================================================

// ===============================================================

const SearchArea = (props) => {
  const { searchPlaceholder, buttonText, buttonLink, handleSearch } = props;
  const downSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function onChange(e) {
    handleSearch(e.target.value)
  }
  return (
    <FlexBox mb={2} gap={2} justifyContent="space-between" flexWrap="wrap">

      <Button
        color="info"
        fullWidth={downSM}
        variant="contained"
        startIcon={<Add />}
        component={Link}
        to={buttonLink}
        sx={{
          minHeight: 44,
        }}
      >
        {buttonText}
      </Button>
    </FlexBox>
  );
};
SearchArea.defaultProps = {
  buttonText: "Task erstellen",
  searchPlaceholder: "Task suchen...",
};
export default SearchArea;
