import { useLocation } from "react-router-dom";

import { FlexBox } from "components/flex-box";
import { Span } from "components/Typography"; // CUSTOM ICON COMPONENT

import { MainContainer, StyledNavLink } from "./styles";

const Navigation = ({}) => {
  const pathname = useLocation().pathname;
  return (
    <MainContainer sx={{paddingTop: '15px'}}>
      <StyledNavLink
        to="/administration/projects"
        key="projects"
        isCurrentPath={pathname === "/administration/projects"}
      >
        <FlexBox alignItems="center" gap={1}>
          <Span>Projekte verwalten</Span>
        </FlexBox>
      </StyledNavLink>
      <StyledNavLink
        to="/administration/account"
        key="account"
        isCurrentPath={pathname === "/administration/account"}
      >
        <FlexBox alignItems="center" gap={1}>
          <Span>Account verwalten</Span>
        </FlexBox>
      </StyledNavLink>
    </MainContainer>
  );
};

export default Navigation;
