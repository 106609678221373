import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAlertMessageStore, useLoadingStore } from "store/store";
import UserDataService from "services/user.service";

import Box from "@mui/material/Box";

import { AdministrationLayout } from "components/layouts/administration";
import AccountForm from "./account-form";

export default function AdministrationAccount() {
  const [account, setAccount] = useState({
    username: "",
    email: "",
    firstname: "",
    surname: "",
    password: "",
    confirmPassword: "",
  });
  const { key } = useParams();
  const navigate = useNavigate();

  const handleFormSubmit = data => {
    useLoadingStore.setState({ isLoading: true });
    /*ProjectDataService.updateSingle(key, data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Account wurde erfolgreich gespeichert",
          },
        });
        navigate("/administration/account")
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Speichern des Accounts",
          },
        });
      });*/
  };

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    getAccountDetails();
  }, []);

  function getAccountDetails() {
    UserDataService.getSingle(key)
      .then((response) => {
        useLoadingStore.setState({ isLoading: false });
        setAccount(response.data)
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }

  return (
    <AdministrationLayout title="Account verwalten">
      <Box>
        <AccountForm handleFormSubmit={handleFormSubmit} account={account} />
      </Box>
    </AdministrationLayout>
  );
}
