import { Edit } from "@mui/icons-material";
import {
  StyledIconButton,
  StyledTableCell,
  StyledTableRow,
} from "pages-sections/StyledComponents";
import TaskDeleteButton from "./TaskDeleteButton";

// ========================================================
const ProjectRow = ({ task }) => {

  return (
    <>
      <StyledTableRow tabIndex={-1} role="checkbox">
        <StyledTableCell align="left">{task?.description}</StyledTableCell>
        <StyledTableCell align="left">{task?.title}</StyledTableCell>
        <StyledTableCell align="center">
          <StyledIconButton href={`/jira/tasks/${task.id}`}>
            <Edit />
          </StyledIconButton>
          <TaskDeleteButton task={task} />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default ProjectRow;
