import { useEffect, useState } from "react";

import { useAlertMessageStore, useLoadingStore } from "store/store";
import ProjectDataService from "services/project.service";
import useTimesheet from "hooks/useTimesheet";

import Card from "@mui/material/Card";
import { TimesheetLayout } from "components/layouts/timesheet";
import TimesheetForm from "./timesheet-form";

export default function TimesheetProject() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    getProjects();
  }, []);

  function getProjects() {
    ProjectDataService.getAll()
      .then((response) => {
        setProjects(response.data.projects);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }
  const INITIAL_VALUES = {
    project: "",
    startDate: null,
    endDate: null,
    invoice: "",
  };

  const { getPDFProject } = useTimesheet();

  return (
    <TimesheetLayout title="Zeitnachweis">
      <Card
        sx={{
          p: 0,
          mb: "30px",
        }}
      >
        <TimesheetForm
          initialValues={INITIAL_VALUES}
          handleFormSubmit={getPDFProject}
          projectValues={projects}
        />
      </Card>
    </TimesheetLayout>
  );
}
