import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

import TaskDeleteDialog from "./task-delete-dialog";

import useTaskActions from "hooks/useTaskActions";

// ========================================================================

// ========================================================================

const TaskDeleteButton = ({ task }) => {
  const { openModal, handleDeleteClick, toggleDeleteDialog } = useTaskActions();

  return (
    <>
      <TaskDeleteDialog
        openDialog={openModal}
        handleCloseDialog={toggleDeleteDialog}
        handleDeleteClick={handleDeleteClick}
        task={task}
      />
      <IconButton onClick={toggleDeleteDialog}>
        <Delete />
      </IconButton>
    </>
  );
};
export default TaskDeleteButton;
