import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

const VALIDATION_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email("Bitte eine gültige E-Mail eingeben")
    .required("Bitte eine E-Mail eingeben"),
  firstname: yup
    .string("Bitte einen gültigen Vornamen eingeben")
    .required("Bitte einen Vornamen eingeben"),
  surname: yup
    .string("Bitte einen gültigen Nachnamen eingeben")
    .required("Bitte einen Nachnamen eingeben"),
  password: yup
    .string("Bitte ein gültiges Kennwort eingeben")
    .nullable()
    .optional()
    .min(8, "Das Kennwort muss mindestens 8 Zeichen lang sein")
    .max(500, "Das Kennwort darf maximal 500 Zeichen lang sein"),
  confirmPassword: yup.string().when("password", {
    is: (password) => !!password,
    then: () =>
      yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Die Kennwörter stimmen nicht überein"
        )
        .required("Bitte das neue Kennwort wiederholen"),
  }),
}); // ================================================================

// ================================================================
const AccountForm = (props) => {
  const { handleFormSubmit, account } = props;

  return (
    <Card
      sx={{
        p: { xs: 1, md: 6 },
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={account}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize={true}
      >
        {({
          dirty,
          errors,
          touched,
          values,
          isSubmitting,
          isValid,
          isValidating,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="username"
                    label="Benutzername"
                    name="username"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    required
                    disabled
                  />
                  <FormHelperText
                    error={Boolean(touched.username && errors.username)}
                  >
                    {touched.username && errors.username ? errors.username : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="email"
                    label="E-Mail"
                    name="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    required
                  />
                  <FormHelperText
                    error={Boolean(touched.email && errors.email)}
                  >
                    {touched.email && errors.email ? errors.email : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="firstname"
                    label="Vorname"
                    name="firstname"
                    variant="outlined"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    required
                  />
                  <FormHelperText
                    error={Boolean(touched.firstname && errors.firstname)}
                  >
                    {touched.firstname && errors.firstname
                      ? errors.firstname
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="surname"
                    label="Nachname"
                    name="surname"
                    variant="outlined"
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.surname && errors.surname)}
                    fullWidth
                    required
                  />
                  <FormHelperText
                    error={Boolean(touched.surname && errors.surname)}
                  >
                    {touched.surname && errors.surname ? errors.surname : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="password"
                    label="Neues Kennwort"
                    name="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    type="password"
                  />
                  <FormHelperText
                    error={Boolean(touched.password && errors.password)}
                  >
                    {touched.password && errors.password ? errors.password : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="confirmPassword"
                    label="Kennwort wiederholen"
                    name="confirmPassword"
                    variant="outlined"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      (touched.confirmPassword ||
                        (values.password && !errors.password)) &&
                        errors.confirmPassword
                    )}
                    fullWidth
                    type="password"
                  />
                  <FormHelperText
                    error={Boolean(
                      (touched.confirmPassword ||
                        (values.password && !errors.password)) &&
                        errors.confirmPassword
                    )}
                  >
                    {(touched.confirmPassword ||
                      (values.password && !errors.password)) &&
                    errors.confirmPassword
                      ? errors.confirmPassword
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  sx={{
                    display: "block",
                    margin: "auto",
                    textTransform: "uppercase",
                  }}
                  disabled={(!dirty || (dirty &&!isValid))}
                >
                  Speichern
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default AccountForm;
