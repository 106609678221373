import { useEffect, useState } from "react";

import TaskDataService from "services/task.service";
import { useLoadingStore, useReloadStore } from "store/store";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import { JiraLayout } from "components/layouts/jira";
import Scrollbar from "components/Scrollbar";
import SearchArea from "components/tasks/SearchArea";
import SimpleTableHeader from "components/data-table/SimpleTableHeader";
import TaskRow from "components/tasks/TaskRow";

export const tableHeading = [
  {
    id: "description",
    label: "Beschreibung",
    align: "left",
  },
  {
    id: "title",
    label: "Titel",
    align: "left",
  },
  {
    id: "action",
    label: "Aktion",
    align: "center",
  },
];

export default function ManageTasks() {
  const [tasks, setTasks] = useState([]);
  const [shouldReload, setShouldReload] = useState(
    useReloadStore.getState().shouldReload
  );

  const subReloadStore = useReloadStore.subscribe((reloadstore) => {
    setShouldReload(reloadstore.shouldReload);
  });

  function getTasks() {
    TaskDataService.getAll()
      .then((response) => {
        setTasks(response.data.tasks);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    getTasks();
  }, []);

  useEffect(() => {
    if (shouldReload === true) {
      useLoadingStore.setState({ isLoading: true });
      getTasks();
      useReloadStore.setState({ shouldReload: false });
    }
  }, [shouldReload]);

  return (
    <JiraLayout title="Tasks verwalten">
      <Box>
        <SearchArea buttonLink="/jira/tasks/create/" />
        <Card>
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 900,
              }}
            >
              <Table>
                <SimpleTableHeader heading={tableHeading} />
                <TableBody>
                  {tasks.map((task) => (
                    <TaskRow task={task} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Box>
    </JiraLayout>
  );
}
