import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik } from "formik";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

const VALIDATION_SCHEMA = yup.object().shape({
  consultant: yup
    .object()
    .nullable(true)
    .required("Bitte einen Bearbeiter wählen"),
  startDate: yup.date().required("Bitte ein Startdatum eingeben"),
  endDate: yup.date().required("Bitte ein Enddatum eingeben"),
  invoice: yup.string()
}); // ================================================================

// ================================================================
const TimesheetForm = (props) => {
  const { initialValues, handleFormSubmit, consultantValues } = props;

  return (
    <Card
      sx={{
        p: { xs: 1, md: 6 },
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        consultantValues={consultantValues}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <Autocomplete
                  options={consultantValues}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bearbeiter"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                        "aria-autocomplete": "none",
                      }}
                      onBlur={handleBlur}
                      error={!!touched.consultant && !!errors.consultant}
                      helperText={touched.consultant && errors.consultant}
                      required={true}
                    />
                  )}
                  onChange={(e, value) => setFieldValue("consultant", value)}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <DatePicker
                  label="Startdatum"
                  value={values.startDate}
                  onChange={(newValue) => setFieldValue("startDate", newValue)}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      sx: {
                        mb: 1,
                      },
                      size: "medium",
                      fullWidth: true,
                      error: Boolean(!!touched.startDate && !!errors.startDate),
                      helperText: touched.startDate && errors.startDate,
                    },
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <DatePicker
                  label="Enddatum"
                  value={values.endDate}
                  onChange={(newValue) => setFieldValue("endDate", newValue)}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      sx: {
                        mb: 1,
                      },
                      size: "medium",
                      fullWidth: true,
                      error: Boolean(!!touched.endDate && !!errors.endDate),
                      helperText: touched.endDate && errors.endDate,
                    },
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                  <TextField
                    id="invoice"
                    label="Rechnung"
                    name="invoice"
                    variant="outlined"
                    value={values.invoice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.invoice && errors.invoice)}
                    fullWidth
                  />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  sx={{
                    display: "block",
                    margin: "auto",
                    textTransform: "uppercase",
                  }}
                >
                  PDF erzeugen
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default TimesheetForm;
