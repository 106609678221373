import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent"; // MUI ICON COMPONENTS

import Close from "@mui/icons-material/Close";

import { H2, Paragraph } from "components/Typography"; // LOCAL CUSTOM HOOKS

// =====================================================
const ProjectDeleteDialog = (props) => {
  const { project, openDialog, handleCloseDialog, handleDeleteClick } = props;

  return (
    <Dialog
      open={openDialog}
      maxWidth={false}
      onClose={handleCloseDialog}
      sx={{
        zIndex: 1501,
      }}
    >
      <DialogContent
        sx={{
          maxWidth: 900,
          width: "100%",
        }}
      >
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} alignSelf="center">
              <H2>Projekt löschen</H2>

              <Paragraph my={2}>
                Soll das folgende Projekt wirklich gelöscht werden?
              </Paragraph>

              <Paragraph my={0}>
                <b>ID:</b> {project.id}
              </Paragraph>

              <Paragraph my={0}>
                <b>Name:</b> {project.displayName}
              </Paragraph>

              <Paragraph my={0}><b>Jira:</b> {project.jiraKey}</Paragraph>

              <Paragraph my={0}><b>SevDesk:</b> {project.sevdeskID}</Paragraph>

              <Paragraph my={0}><b>Status:</b> {project.active ? "Aktiv" : "Inaktiv"}</Paragraph>

              <Divider
                sx={{
                  mb: 2,
                }}
              />
              <Grid container spacing={3} pl={3}>
                <Grid item xs={6} alignSelf="center">
                  <Button variant="contained" onClick={() => handleDeleteClick(project?.id)}>
                    Löschen
                  </Button>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                  <Button variant="outlined" onClick={handleCloseDialog}>
                    Abbrechen
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <IconButton
          sx={{
            position: "absolute",
            top: 3,
            right: 3,
          }}
          onClick={handleCloseDialog}
        >
          <Close fontSize="small" color="primary" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDeleteDialog;
