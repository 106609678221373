import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { authenticationService } from "../auth/authentication.service";
import { Formik } from "formik";
import { object, string } from "yup";
import { CssBaseline } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { useLoadingStore, useAlertMessageStore } from "store/store";

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    useLoadingStore.setState({ isLoading: false });
  }, []);

  const handleFormSubmit = (values) => {
    useLoadingStore.setState({ isLoading: true });
    authenticationService
      .login(values.username, values.password, values.token)
      .then(
        (user) => {
          const { from } = location.pathname || {
            from: { pathname: "/" },
          };
          navigate(from);
        },
        (error) => {
          useLoadingStore.setState({ isLoading: false });
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler bei der Anmeldung",
            },
          });
        }
      );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Formik
        initialValues={{
          username: "",
          password: "",
          token: "",
        }}
        validationSchema={object().shape({
          username: string().required("Bitte einen Benutzernamen eingeben"),
          password: string().required("Bitte ein Passwort eingeben"),
          token: string()
            .required("Bitte einen Token eingeben")
            .length(6, "Bitte einen gültigen Token eingeben"),
        })}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Box mt={8}>
              <img
                alt="logo"
                src="/logo.png"
                style={{ display: "block", margin: "auto" }}
              />
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="username"
                    label="Benutzername"
                    name="username"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.username && errors.username)}
                  />
                  <FormHelperText
                    error={Boolean(touched.username && errors.username)}
                  >
                    {touched.username && errors.username ? errors.username : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.password && errors.password)}
                >
                  <TextField
                    name="password"
                    label="Passwort"
                    type="password"
                    id="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                  />
                  <FormHelperText
                    error={Boolean(touched.password && errors.password)}
                  >
                    {touched.password && errors.password ? errors.password : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.token && errors.token)}
                >
                  <TextField
                    name="token"
                    label="Token"
                    type="token"
                    id="token"
                    variant="outlined"
                    value={values.token}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.token && errors.token)}
                  />
                  <FormHelperText
                    error={Boolean(touched.token && errors.token)}
                  >
                    {touched.token && errors.token ? errors.token : ""}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={Boolean(!isValid)}
                >
                  {"Anmelden"}
                </Button>
              </form>
              </Box>
          </>
        )}
      </Formik>
    </Container>
  );
}
