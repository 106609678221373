import { useLocation } from "react-router-dom";
import { Fragment } from "react"; // MUI ICON COMPONENTS

import { FlexBox } from "components/flex-box";
import { Span } from "components/Typography"; // CUSTOM ICON COMPONENT

import { MainContainer, StyledNavLink } from "./styles";

const Navigation = ({}) => {
  const pathname = useLocation().pathname;
  return (
    <MainContainer sx={{paddingTop: '15px'}}>
      <StyledNavLink
        to="/jira/import"
        key="import"
        isCurrentPath={pathname === "/jira/import"}
      >
        <FlexBox alignItems="center" gap={1}>
          <Span>Import</Span>
        </FlexBox>
      </StyledNavLink>
      <StyledNavLink
        to="/jira/tasks"
        key="tasks"
        isCurrentPath={pathname === "/jira/tasks"}
      >
        <FlexBox alignItems="center" gap={1}>
          <Span>Tasks verwalten</Span>
        </FlexBox>
      </StyledNavLink>
    </MainContainer>
  );
};

export default Navigation;
