import { useEffect } from "react";

import { useLoadingStore } from "store/store";
import useTimesheet from "hooks/useTimesheet";

import Card from "@mui/material/Card";
import { TimesheetLayout } from "components/layouts/timesheet";
import TimesheetForm from "./timesheet-form";

export default function TimesheetUser() {
  useEffect(() => {
    useLoadingStore.setState({ isLoading: false });
  }, []);

  const INITIAL_VALUES = {
    consultant: "",
    startDate: null,
    endDate: null,
    invoice: "",
  };

  const consultants = [
    {
      id: "c.oehler",
      name: "Christopher Oehler",
    },
    {
      id: "s.oehler",
      name: "Steffen Oehler",
    },
  ];

  const { getPDFUser } = useTimesheet();

  return (
    <TimesheetLayout title="Zeitnachweis">
      <Card
        sx={{
          p: 0,
          mb: "30px",
        }}
      >
        <TimesheetForm
          initialValues={INITIAL_VALUES}
          handleFormSubmit={getPDFUser}
          consultantValues={consultants}
        />
      </Card>
    </TimesheetLayout>
  );
}
