import { useEffect, useState } from "react";

import {
  useAlertMessageStore,
  useLoadingStore,
  useReloadStore,
} from "store/store";
import ProjectDataService from "services/project.service";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import { AdministrationLayout } from "components/layouts/administration";
import Scrollbar from "components/Scrollbar";
import SimpleTableHeader from "components/data-table/SimpleTableHeader";
import ProjectRow from "components/administration/ProjectRow";
import SearchArea from "components/administration/SearchArea";

export const tableHeading = [
  {
    id: "displayName",
    label: "Name",
    align: "left",
  },
  {
    id: "jiraKey",
    label: "Jira",
    align: "left",
  },
  {
    id: "sevdeskID",
    label: "SevDesk",
    align: "left",
  },
  {
    id: "active",
    label: "Status",
    align: "left",
  },
  {
    id: "action",
    label: "Aktion",
    align: "left",
  },
];

export default function AdministrationProjects() {
  const [projects, setProjects] = useState([]);
  const [shouldReload, setShouldReload] = useState(
    useReloadStore.getState().shouldReload
  );

  const subReloadStore = useReloadStore.subscribe((reloadstore) => {
    setShouldReload(reloadstore.shouldReload);
  });

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    getProjects();
  }, []);

  useEffect(() => {
    if (shouldReload === true) {
      useLoadingStore.setState({ isLoading: true });
      getProjects();
      useReloadStore.setState({ shouldReload: false });
    }
  }, [shouldReload]);

  function getProjects() {
    ProjectDataService.getAll(false)
      .then((response) => {
        setProjects(response.data.projects);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }

  return (
    <AdministrationLayout title="Projekte verwalten">
      <Box>
        <SearchArea buttonLink="/administration/projects/create/" />
        <Card>
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 900,
              }}
            >
              <Table>
                <SimpleTableHeader heading={tableHeading} />
                <TableBody>
                  {projects.map((project) => (
                    <ProjectRow project={project} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Box>
    </AdministrationLayout>
  );
}
