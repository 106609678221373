import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery"; // MUI ICON COMPONENTS
import { FlexBetween, FlexBox } from "components/flex-box"; // CUSTOM UTILS LIBRARY FUNCTION
import { Paragraph } from "components/Typography";
import Scrollbar from "components/Scrollbar";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
//import TablePagination from "components/data-table/TablePagination";
import useMuiTable from "hooks/useMuiTable";
import TablePagination from "components/data-table/TablePagination";
import LaunchIcon from "@mui/icons-material/Launch";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
}));

// ==============================================================
const TicketsOverview = ({ project, tickets, title }) => {
  const { rowsPerPage, filteredList, handleChangePage } = useMuiTable({
    listData: tickets,
  });

  const jiraURL = project !== "all" ? `https://tickets.new-zone.de/issues/?jql=project=${project}` : "https://tickets.new-zone.de/issues/?jql=" 

  return (
    <Card
      sx={{
        p: 0,
        mb: "30px",
      }}
    >
      <FlexBetween px={3} py={2} flexWrap="wrap" bgcolor="grey.200">
        <FlexBox gap={1} alignItems="center">
          <Paragraph color="grey.600">Anzahl offener {title}: </Paragraph>
          <Paragraph>{tickets.length}</Paragraph>
          <Tooltip title="Jira öffnen">
            <IconButton href={jiraURL} target="_blank">
              <LaunchIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </FlexBox>
      </FlexBetween>
      {tickets.length > 0 ? (
        <>
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 900,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Beschreibung
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Projekt</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Komponente
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Bearbeiter
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredList.map((ticket) => (
                    <TableRow
                      component={Link}
                      to={`https://tickets.new-zone.de/browse/${ticket.key}`}
                      target="_blank"
                    >
                      <StyledTableCell>{ticket.fields.summary}</StyledTableCell>
                      <StyledTableCell>
                        {ticket.key?.substring(0, ticket.key.indexOf("-"))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ticket.fields.components[0]?.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ticket.fields.assignee?.displayName}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Stack alignItems="center" my={4}>
            <TablePagination
              onChange={handleChangePage}
              count={Math.ceil(tickets.length / rowsPerPage)}
            />
          </Stack>
        </>
      ) : (
        <FlexBetween px={3} py={2} flexWrap="wrap">
          <FlexBox gap={1} alignItems="center">
            <Paragraph color="grey.600">
              Es wurden keine offenen {title} gefunden
            </Paragraph>
          </FlexBox>
        </FlexBetween>
      )}
    </Card>
  );
};

export default TicketsOverview;
