import Switch from "@mui/material/Switch";

import useProjectActions from "hooks/useProjectActions";

// ========================================================================

// ========================================================================

const ProjectActiveSwitch = ({ project }) => {
  const { handleChangeActive } = useProjectActions({});

  return (
    <Switch checked={project.active} onChange={() => handleChangeActive(project)} />
  );
};
export default ProjectActiveSwitch;
