import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

import ProjectDeleteDialog from "./project-delete-dialog";

import useProjectActions from "hooks/useProjectActions";

// ========================================================================

// ========================================================================

const ProjectDeleteButton = ({ project }) => {
  const { openModal, handleDeleteClick, toggleDeleteDialog } = useProjectActions();

  return (
    <>
      <ProjectDeleteDialog
        openDialog={openModal}
        handleCloseDialog={toggleDeleteDialog}
        handleDeleteClick={handleDeleteClick}
        project={project}
      />
      <IconButton onClick={toggleDeleteDialog}>
        <Delete />
      </IconButton>
    </>
  );
};
export default ProjectDeleteButton;
