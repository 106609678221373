import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import { array } from "yup";
// ========================================================
const ComponentBox = ({ project, values, setFieldValue, FieldArray }) => {
  return (
    <Paper key={project.id}>
      <FieldArray
        name={`components.${project.id}`}
        render={(arrayHelpers) => (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={project.displayName}
              id={project.id}
            >
              <FormControlLabel
                label={project.displayName}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                control={
                  <Checkbox
                    id={project.name}
                    checked={
                      project.components.length ===
                        values.components[project.id]?.length ||
                      (project.components.length === 0 &&
                        values.components[project.id]?.length === 0)
                    }
                    indeterminate={
                      project.components.length !==
                        values.components[project.id]?.length &&
                      values.components[project.id]?.length > 0
                    }
                    onChange={(e, value) => {
                      if (value === true) {
                        values.components[project.id] = [];
                        project.components.map((component) => {
                          arrayHelpers.push(component.id);
                        });
                        if (project.components.length === 0) {
                          setFieldValue("components", values.components);
                        }
                        return values.components[project.id];
                      } else {
                        delete values.components[project.id];
                        setFieldValue("components", values.components);
                      }
                    }}
                  />
                }
              />
            </AccordionSummary>
            {project.components.length > 0 && (
              <AccordionDetails>
                <Container>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="positions" column>
                      {project.components.map((component) => {
                        return (
                          <FormControlLabel
                            label={component.name}
                            control={
                              <Checkbox
                                id={component.name}
                                checked={
                                  values.components[project.id]?.includes(
                                    component.id
                                  ) || false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(component.id);
                                  } else {
                                    const idx = values.components[
                                      project.id
                                    ].indexOf(component.id);
                                    arrayHelpers.remove(idx);
                                    if (
                                      values.components[project.id].length === 1
                                    ) {
                                      delete values.components[project.id];
                                      setFieldValue(
                                        "components",
                                        values.components
                                      );
                                    }
                                  }
                                }}
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Container>
              </AccordionDetails>
            )}
          </Accordion>
        )}
      />
    </Paper>
  );
};

export default ComponentBox;
