import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container"; // Local CUSTOM COMPONENTS

import Navigation from "./navigation";

import Header from "pages-sections/header";
import { useParams } from "react-router-dom";

function useFindTitleByKey(array) {
  const { key } = useParams();
  const project = array.find((element) => {
    return element.id === key;
  });
  return project ? project.displayName : "Übersicht";
}

const DashboardLayout = ({ children, projects }) => (
  <Container
    maxWidth="xxl"
    sx={{
      my: 4,
      px: { xs: 0, md: 2 }
    }}
  >
    <Grid container spacing={3}>
      <Grid
        item
        lg={3}
        xs={12}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
          },
        }}
      >
        <Navigation projects={projects} />
      </Grid>

      <Grid item lg={9} xs={12}>
        <Header title={useFindTitleByKey(projects)} section="dashboard" navigationData={projects} />
        {children}
      </Grid>
    </Grid>
  </Container>
);

export default DashboardLayout;
