import { create } from "zustand";

export const useAlertMessageStore = create(() => ({ alertMessage: false }));

export const useLoadingStore = create(() => ({ isLoading: true }));

export const useReloadStore = create(() => ({ shouldReload: false }));

export const useUserStore = create(() => ({
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || false,
}));
