import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoadingStore, useUserStore } from "store/store";
import ProjectDataService from "services/project.service";
import { DashboardLayout } from "components/layouts/dashboard";
import InvoicesOverview from "pages-sections/invoices-overview";
import InvoicesCustomer from "pages-sections/invoices-customer";
import TicketsOverview from "pages-sections/tickets-overview";

export default function Home() {
  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [cancellations, setCancellations] = useState([]);
  let { key = "all" } = useParams();

  function filterCancellations(cancellations, invoices) {
    cancellations.map((cancellation) => {
      const index = invoices.findIndex(
        (invoice) => invoice.id === cancellation.origin.id
      );
      cancellation.originInvoice = invoices[index];
      invoices.splice(index, 1);
    });
    setInvoices(invoices);
    setCancellations(cancellations);
  }

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.getAll(true).then((response) => {
      setProjects(response.data.projects);
    });
  }, []);

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.getOverview(key).then((response) => {
      filterCancellations(response.data.cancellations, response.data.invoices);
      setTickets(response.data.tickets);
      useLoadingStore.setState({ isLoading: false });
    });
  }, [key]);

  return (
    <DashboardLayout projects={projects}>
      {key === "all" ? (
        <>
          <TicketsOverview 
            project={key} tickets={tickets} title="Tickets" />
          <InvoicesOverview
            invoices={invoices}
            title="Rechnungen"
            invoiceType="RE"
          />
          <InvoicesOverview
            invoices={cancellations}
            title="Stornos"
            invoiceType="SR"
          />
        </>
      ) : (
        <>
          <TicketsOverview 
            project={key} tickets={tickets} title="Tickets" />
          <InvoicesCustomer
            invoices={invoices}
            title="Rechnungen"
            invoiceType="RE"
            project={key}
          />
          <InvoicesCustomer
            invoices={cancellations}
            title="Stornos"
            invoiceType="SR"
            project={key}
          />
        </>
      )}
    </DashboardLayout>
  );
}
