import '../css/App.css';
import React, { Component } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

  
class Loading extends Component {
    render() {

        return (
            <Backdrop sx={{zIndex: (theme) => theme.zIndex.drawer+1}} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

export default (Loading)