import http from "../http-common";

class UserDataService {
  getSingle(){
    return http.get(`users/getDetails`)
  }
  login(data) {
    return http.post(`/users/login`, data);
  }

}

export default new UserDataService();
