import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

const VALIDATION_SCHEMA = yup.object().shape({
  id: yup
    .string("Bitte einen gültigen Schlüssel eingeben")
    .required("Bitte einen Schlüssel eingeben"),
  displayName: yup.string().required("Bitte einen Anzeigenamen eingeben"),
  jiraKey: yup.string("Bitte einen gültigen Jiraschlüssel eingeben"),
  sevdeskID: yup.number("Bitte eine gültige SevDesk-ID eingeben"),
}); // ================================================================

// ================================================================
const ProjectForm = (props) => {
  const { handleFormSubmit, project } = props;

  return (
    <Card
      sx={{
        p: { xs: 1, md: 6 },
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={project}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          isValidating,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="id"
                    label="Schlüssel"
                    name="id"
                    variant="outlined"
                    value={values.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.id && errors.id)}
                    fullWidth
                    required
                  />
                  <FormHelperText error={Boolean(touched.id && errors.id)}>
                    {touched.id && errors.id ? errors.id : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  label="Status"
                  labelPlacement="top"
                  control={
                    <Switch
                      id="active"
                      name="active"
                      checked={values.active}
                      onChange={(event, checked) =>
                        setFieldValue("active", checked)
                      }
                      onBlur={handleBlur}
                      error={Boolean(touched.active && errors.active)}
                      fullWidth
                    />
                  }
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="displayName"
                    label="Anzeigename"
                    name="displayName"
                    variant="outlined"
                    value={values.displayName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.displayName && errors.displayName)}
                    fullWidth
                    required
                  />
                  <FormHelperText
                    error={Boolean(touched.displayName && errors.displayName)}
                  >
                    {touched.displayName && errors.displayName
                      ? errors.displayName
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="jiraKey"
                    label="Jira"
                    name="jiraKey"
                    variant="outlined"
                    value={values.jiraKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.jiraKey && errors.jiraKey)}
                    fullWidth
                  />
                  <FormHelperText
                    error={Boolean(touched.jiraKey && errors.jiraKey)}
                  >
                    {touched.jiraKey && errors.jiraKey ? errors.jiraKey : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="sevdeskID"
                    label="SevDesk"
                    name="sevdeskID"
                    variant="outlined"
                    value={values.sevdeskID}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.sevdeskID && errors.sevdeskID)}
                    fullWidth
                  />
                  <FormHelperText
                    error={Boolean(touched.sevdeskID && errors.sevdeskID)}
                  >
                    {touched.sevdeskID && errors.sevdeskID
                      ? errors.sevdeskID
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  sx={{
                    display: "block",
                    margin: "auto",
                    textTransform: "uppercase",
                  }}
                >
                  Speichern
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default ProjectForm;
