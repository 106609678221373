import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import JiraDataService from "services/jira.service";
import { useAlertMessageStore, useLoadingStore } from "store/store";
import TaskDataService from "services/task.service";

import Box from "@mui/material/Box";

import { JiraLayout } from "components/layouts/jira";
import TaskForm from "./task-form";

export default function TaskDetails() {
  const [projects, setProjects] = useState([]);
  const [task, setTask] = useState({
    description: "",
    title: "",
    components: {},
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const handleFormSubmit = (data) => {
    useLoadingStore.setState({ isLoading: true });
    TaskDataService.updateSingle(id, data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Task wurde erfolgreich gespeichert",
          },
        });
        navigate("/jira/tasks");
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Speichern des Tasks",
          },
        });
      });
  };

  useEffect(() => {
    function getTask() {
      TaskDataService.getSingle(id)
        .then((response) => {
          setTask(response.data);
          useLoadingStore.setState({ isLoading: false });
        })
        .catch((error) => {
          useLoadingStore.setState({ isLoading: false });
          console.dir(error);
        });
    }
    function getComponents() {
      JiraDataService.getComponents("all")
        .then((response) => {
          setProjects(response.data);
          useLoadingStore.setState({ isLoading: false });
        })
        .catch((error) => {
          useLoadingStore.setState({ isLoading: false });
          console.dir(error);
        });
    }

    useLoadingStore.setState({ isLoading: true });
    getTask();
    getComponents();
  }, [id]);

  return (
    <JiraLayout title="Task bearbeiten">
      <Box>
        <TaskForm
          handleFormSubmit={handleFormSubmit}
          task={task}
          projects={projects}
          buttonText="Task speichern"
        />
      </Box>
    </JiraLayout>
  );
}
