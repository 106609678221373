import { useAlertMessageStore, useLoadingStore } from "store/store";
import SevDeskDataService from "services/sevdesk.service";

// ================================================================

// ================================================================

// ================================================================

// ================================================================

const useInvoice = (props) => {
  const getPDF = (invoice) => {
    if (invoice.status === "200" || invoice.status === "1000") {
      useLoadingStore.setState({ isLoading: true });
      SevDeskDataService.getPDF(invoice.id).then((response) => {
        useLoadingStore.setState({ isLoading: false });
        const filename =
          response.headers["content-disposition"].split("filename=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    } else {
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "warning",
          message: "Die Rechnung befindet sich noch im Entwurf",
        },
      });
    }
  };

  return {
    getPDF,
  };
};
export default useInvoice;
