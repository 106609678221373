import Box from "@mui/material/Box";

import { MobileMenu } from "components/navbar/mobile-menu";
import { FlexBetween } from "components/flex-box"; // GLOBAL CUSTOM HOOK


// ==============================================================
const MobileHeader = () => {

  return (
    <FlexBetween width="100%">
      <Box flex={1}>
        <MobileMenu />
      </Box>
    </FlexBetween>
  );
};

export default MobileHeader;
