import { useCallback, useState } from "react";
import {
  useAlertMessageStore,
  useReloadStore,
} from "store/store";
import TaskDataService from "services/task.service";

// ================================================================

// ================================================================

const useTaskActions = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleDeleteDialog = useCallback(
    () => setOpenModal((open) => !open),
    []
  );

  const handleDeleteClick = (id) => {
    TaskDataService.deleteSingle(id)
      .then((response) => {
        toggleDeleteDialog();
        useReloadStore.setState({ shouldReload: true });
        if (response.status === 200) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "success",
              message: "Der Task wurde erfolgreich gelöscht",
            },
          });
        } else {
          useAlertMessageStore.setState({
            alertMessage: { severity: "error", message: response.data.message },
          });
        }
      })
      .catch((e) => {
        toggleDeleteDialog();
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Löschen des Tasks",
          },
        });
      });
  };

  return {
    openModal,
    toggleDeleteDialog,
    handleDeleteClick,
  };
};
export default useTaskActions;
