import http from "../http-common";

class ClassSessionDataService {
  getAll() {
    return http.get(`/sessions`);
  }

  get(id) {
    return http.get(`sessions/${id}`);
  }

  delete(id) {
    return http.delete(`/sessions/${id}`);
  }

}

const SessionDataService = new ClassSessionDataService();
export default SessionDataService;
