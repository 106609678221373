import { BehaviorSubject } from "rxjs";
import { authHeader } from "../helpers/auth-header";
import { handleResponse } from "../helpers/handle-response";
import SessionDataService from "../services/session.service";
import { useUserStore } from "store/store";

const base_url = process.env.REACT_APP_BASE_URL;

export const authenticationService = {
  login,
  logout,
};

function login(username, password, token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password, token }),
  };

  return fetch(`${base_url}/api/users/login`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let userSubject = response.auth;
      userSubject = { ...userSubject, ...response.account };
      localStorage.setItem("currentUser", JSON.stringify(userSubject));
      localStorage.setItem("session", response.session.sessionToken);
      useUserStore.setState({ currentUser: userSubject });

      return response.auth;
    });
}

function logout() {
  const session_token = localStorage.getItem("session");
  SessionDataService.delete(session_token);
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  localStorage.removeItem("session");
  useUserStore.setState({ currentUser: false });
  authHeader();
}
