import axios from "axios";
import { authHeader } from "./helpers/auth-header";
import { useAlertMessageStore } from "./store/store";
import { authenticationService } from "auth/authentication.service";
import { handleResponseAxios } from "helpers/handle-response";

const base_url = process.env.REACT_APP_BASE_URL;

//"Content-type": "application/json"
const http = axios.create({
  baseURL: `${base_url}/api`,
  headers: { "Content-type": "application/json" },
});

http.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authKey = authHeader();
  config.headers["Authorization"] = authKey;
  return config;
});



http.interceptors.response.use(function (response) {
  return response
}, function (err) {
  return handleResponseAxios(err);
});

export default http;
