import { useState } from "react";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

import TaskDataService from "services/task.service";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Formik, FieldArray } from "formik";
import Grid from "@mui/material/Grid";
import { Masonry } from "@mui/lab";
import TextField from "@mui/material/TextField";

import ComponentBox from "components/tasks/ComponentBox";
import { useLoadingStore } from "store/store";

const VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required("Bitte einen Titel eingeben"),
}); // ================================================================

// ================================================================
const ImportTaskForm = (props) => {
  const { initialValues, handleFormSubmit, taskList, projects } = props;

  const handleTemplateChange = (resetForm, setFieldValue, value) => {
    function getTask() {
      TaskDataService.getSingle(value.id)
        .then((response) => {
          setFieldValue("title", response.data.title);
          setFieldValue("components", response.data.components);
          useLoadingStore.setState({ isLoading: false });
        })
        .catch((error) => {
          useLoadingStore.setState({ isLoading: false });
          console.dir(error);
        });
    }

    if (value === null) {
      resetForm();
    } else {
      useLoadingStore.setState({ isLoading: true });
      getTask();
    }
  };

  return (
    <Card
      sx={{
        p: { xs: 1, md: 6 },
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <Autocomplete
                  options={taskList}
                  getOptionLabel={(option) => `${option?.description}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vorlage"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                        "aria-autocomplete": "none",
                      }}
                      onBlur={handleBlur}
                      error={!!touched.template && !!errors.template}
                      helperText={touched.template && errors.template}
                    />
                  )}
                  onChange={(e, value) =>
                    handleTemplateChange(resetForm, setFieldValue, value)
                  }
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="title"
                  label="Titel"
                  name="title"
                  variant="outlined"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  required={true}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Autocomplete
                  options={[
                    { id: "c.oehler", displayName: "Christopher Oehler" },
                    { id: "s.oehler", displayName: "Steffen Oehler" },
                  ]}
                  getOptionLabel={(option) => `${option?.displayName}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bearbeiter"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                        "aria-autocomplete": "none",
                      }}
                      onBlur={handleBlur}
                      error={!!touched.template && !!errors.template}
                      helperText={touched.template && errors.template}
                      required={true}
                    />
                  )}
                  onChange={(e, value) =>
                    setFieldValue("assignee", value)
                  }
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Masonry
                  columns={{ xs: 1, md: 2, xl: 3 }}
                  spacing={2}
                  sx={{ width: "auto" }}
                >
                  {projects.map((project) => {
                    return (
                      <ComponentBox
                        project={project}
                        values={values}
                        setFieldValue={setFieldValue}
                        FieldArray={FieldArray}
                      />
                    );
                  })}
                </Masonry>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  sx={{
                    display: "block",
                    margin: "auto",
                    textTransform: "uppercase",
                  }}
                >
                  Task importieren
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default ImportTaskForm;
