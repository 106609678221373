import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container"; // Local CUSTOM COMPONENTS

import Navigation from "./navigation";

import Header from "pages-sections/header";

const JiraLayout = ({ children, title }) => (
  <Container
    maxWidth="xxl"
    sx={{
      my: 4,
      px: { xs: 0, md: 2 }
    }}
  >
    <Grid container spacing={3}>
      <Grid
        item
        lg={3}
        xs={12}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
          },
        }}
      >
        <Navigation />
      </Grid>

      <Grid item lg={9} xs={12}>
        <Header title={title} section="jira"/>
        {children}
      </Grid>
    </Grid>
  </Container>
);

export default JiraLayout;
