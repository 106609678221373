const navbarNavigation = [{
  title: "Home",
  megaMenu: false,
  megaMenuWithSub: false,
  url: "/"
}, {
  megaMenu: false,
  megaMenuWithSub: false,
  title: "Zeitnachweis",
  url: "/timesheet/user",
  child: [{
    title: "Benutzer",
    url: "/timesheet/user"
  }, {
    title: "Projekt",
    url: "/timesheet/project"
  }]
}, {
  title: "Jira",
  megaMenu: false,
  megaMenuWithSub: false,
  url: "/jira/import",
  child: [{
    title: "Import",
    url: "/jira/import"
  }, {
    title: "Tasks",
    url: "/jira/tasks"
  }]
}, {
  title: "Verwaltung",
  megaMenu: false,
  megaMenuWithSub: false,
  url: "/administration/projects",
  child: [{
    title: "Projekte",
    url: "/administration/projects"
  }, {
    title: "Account",
    url: "/administration/account"
  }]
}];
export default navbarNavigation;