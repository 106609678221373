import { Link, useLocation } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import clsx from "clsx"; // STYLED COMPONENT

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== "active"
})(({
  theme,
  active
}) => ({
  position: "relative",
  transition: "color 150ms ease-in-out",
  color: active ? theme.palette.primary.main : "inherit",
  "&:hover": {
    color: `${theme.palette.primary.main} !important`
  }
})); // ==============================================================

// ==============================================================
const NavLink = ({
  to,
  children,
  style,
  className,
  ...props
}) => {
  const pathname = useLocation().pathname; // CHECK CURRENT ROUTE
  const checkRouteMatch = () => {
    if (to === "/") return pathname === to;
    return pathname.includes(to);
  };

  return <StyledLink to={to} style={style} className={clsx(className)} active={checkRouteMatch() ? 1 : 0} {...props}>
      {children}
    </StyledLink>;
};

export default NavLink;