import { format } from "date-fns";

import { useAlertMessageStore, useLoadingStore } from "store/store";
import JiraDataService from "services/jira.service";

// ================================================================

// ================================================================

// ================================================================

// ================================================================

const useTimesheet = (props) => {

  const getPDFUser = (values) => {
    useLoadingStore.setState({ isLoading: true });
    const user = values.consultant.id;
    const startDate = format(values.startDate, "yyyy-MM-dd");
    const endDate = format(values.endDate, "yyyy-MM-dd");
    const invoice = values.invoice;
    JiraDataService.getTimesheetPDF("user", user, startDate, endDate, invoice)
      .then((response) => {
        useLoadingStore.setState({ isLoading: false });
        const filename =
          response.headers["content-disposition"].split("filename=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
      });
  };

  const getPDFProject = (values) => {
    useLoadingStore.setState({ isLoading: true });
    const id = values.project.id;
    const startDate = format(new Date(values.startDate), "yyyy-MM-dd");
    const endDate = format(new Date(values.endDate), "yyyy-MM-dd");
    const invoice = values.invoice;
    JiraDataService.getTimesheetPDF("project", id, startDate, endDate, invoice)
      .then((response) => {
        useLoadingStore.setState({ isLoading: false });
        const filename =
          response.headers["content-disposition"].split("filename=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
      });
  };


  return {
    getPDFUser,
    getPDFProject,
  };
};
export default useTimesheet;
